import React, { useRef, useEffect, useState, useContext } from "react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import Header from "../Header";
import HeaderMobile from "../HeaderMobile";
import Footer from "../Footer";
import { IsDesktop, IsMobile } from "../ResponsiveContainers";
import { useModalContext } from "../../context/modalContext";
const Pop500 = dynamic(() => import("../../containers/Modal20Popup"));
const SchedulePopup = dynamic(() => import("../../containers/SchedulePopup"));
import Loading from "../Loading";
import InfoBanner from "../InfoBanner";
import {
  LayoutContainer,
  HeaderContainer,
  MainContainer,
  ContainerWithMax,
  FooterContainer,
  HeaderContainerMobile,
} from "./styled";
import { useHeaderContext } from "../../context/HeaderProvider";
import MainContainerContext from "../../context/MainContainer";
import Head from "next/head";
import { LocationContext } from "../../context/LocationContext";

const AppLayout = ({ children, type, footerBG }) => {
  const location = useContext(LocationContext);
  const { isVisible } = useHeaderContext();
  const wrapperRef = useRef();
  const [showPop500, setShowPop500] = useState(false);
  const [showSchedulePopup, setShowSchedulePopup] = useState(false);
  const [isOriginalPopupActive, setIsOriginalPopupActive] = useState(false);
  const [isPop500Dismissed, setIsPop500Dismissed] = useState(false);

  const pageLoadTime = useRef(Date.now()); // Record the page load time

  // Set up the timer to show Pop500 after 15 seconds
  useEffect(() => {
    const pop500Timer = setTimeout(() => {
      setShowPop500(true);
    }, 15000); // 15 seconds

    return () => {
      clearTimeout(pop500Timer);
    };
  }, []); // Empty dependency array so it runs only once

  // When Pop500 is dismissed, set up the timer for SchedulePopup
  useEffect(() => {
    if (isPop500Dismissed) {
      const timeSincePageLoad = Date.now() - pageLoadTime.current;
      const remainingTimeUntil75Seconds = 75000 - timeSincePageLoad;
      const delay = Math.max(remainingTimeUntil75Seconds, 0);

      const schedulePopupTimer = setTimeout(() => {
        setShowSchedulePopup(true);
      }, delay);

      return () => {
        clearTimeout(schedulePopupTimer);
      };
    }
  }, [isPop500Dismissed]);

  // Remove the immediate show of Pop500 in the location-based useEffect
  useEffect(() => {
    if (location) {
      const customCities = ["Chandler", "Scottsdale", "Dallas"];
      const city = location?.properties?.city?.trim().toLowerCase();
      console.log("City prop received:", city);
      console.log("Location context:", location);

      const normalizedCities = customCities.map(city => city.toLowerCase());
      console.log("Normalized customCities:", normalizedCities);

      if (normalizedCities.includes(city)) {
        console.log("City found in customCities, setting isOriginalPopupActive to true");
        setIsOriginalPopupActive(true);
      } else {
        console.log("City not found in customCities, setting isOriginalPopupActive to false");
        setIsOriginalPopupActive(false);
      }
    } else {
      console.log("Location not available in context.");
    }
  }, [location]);

  const handlePop500Dismiss = () => {
    setShowPop500(false);
    setIsPop500Dismissed(true);
  };

  return (
    <>
      <LayoutContainer className="ant-layout">
        <Head>
          {/* ... */}
        </Head>

        {/* Display Popups based on state */}
        {showPop500 && <Pop500 visible={true} onClose={handlePop500Dismiss} />}
        {showSchedulePopup && <SchedulePopup visible={true} />}

        <InfoBanner role="alert" type="dark" />
        <MainContainerContext.Provider
          value={{ getContainer: () => wrapperRef.current || window }}
        >
          <HeaderContainer isvisible={isVisible ? "yes" : "no"}>
            <Header />
          </HeaderContainer>
          <IsMobile>
            <HeaderContainerMobile>
              <HeaderMobile />
            </HeaderContainerMobile>
          </IsMobile>
          <MainContainer>
            <ContainerWithMax ref={wrapperRef}>{children}</ContainerWithMax>
          </MainContainer>
          <FooterContainer>
            <Footer footerBG={footerBG} />
          </FooterContainer>
        </MainContainerContext.Provider>
      </LayoutContainer>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["noHeader", "noFooter"]),
  footerBG: PropTypes.string,
};

export default AppLayout;
